<template>
  <v-card class="grey lighten-4 pa-5 mt-3">
    <v-row dense>
      <v-col
        align="right"
        cols="12"
        md="12"
        class="pb-4"
      >
        <v-switch
          v-model="contact.is_able"
          :disabled="contact_idx == 0"
          :label="contact.is_able == 0 ? 'Não Habilitado' : 'Habilitado'"
          class="ma-0"
          inset
          hide-details
        />
      </v-col>
      <v-row class="mt-1">
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="contact.department"
            :rules="[rules.required]"
            label="Departamento"
            placeholder="Departamento"
            dense
            rounded
            outlined
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="contact.phone_number"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :rules="[rules.required, rules.validPhoneNumber]"
            label="Telefone"
            placeholder="(xx) x.xxxx-xxxx"
            dense
            rounded
            outlined
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="contact.cel_number"
            v-mask="['(##) ####-####', '(##) #####-####']"
            :rules="[rules.required, rules.validPhoneNumber]"
            label="Celular"
            placeholder="(xx) x.xxxx-xxxx"
            dense
            rounded
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="contact.email"
            label="Email"
            type="email"
            :rules="[rules.required, rules.email]"
            placeholder="exemplo@gmail.com"
            outlined
            rounded
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="contact.name"
            :rules="[rules.required]"
            label="Nome"
            placeholder="Nome completo"
            dense
            rounded
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="contact.password"
            :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.required, rules.minLength(6)]"
            outlined
            label="Senha"
            rounded
            dense
            @click:append="showPassword = !showPassword"
          />
        </v-col>

        <v-col
          align="right"
          cols="12"
          md="12"
        >
          <v-btn
            rounded
            outlined
            color="primary"
            @click="$emit('remove', contact)"
          >
            Remover Contato
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>


<script>
export default {
  props: {
    contact: Object,
    contact_idx: Number,
  },
  data: () => ({
    showPassword: false,
    rules: {
      required: (value) => !!value || "Este campo é obrigatório",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Insira um email válido";
      },
      validPhoneNumber: (value) => {
        const pattern = /\(\d{2}\)\s\d{4,5}-\d{4}/;
        return pattern.test(value) || "Insira um número de telefone válido";
      },
      minLength: (min) => (value) =>
        value && value.length >= min || `Mínimo de ${min} caracteres`,
    },
  }),
};
</script>
